export default {
  name: "ShowErrors",
  data() {
    return {
      errorMessages: {
        required: () => this.fieldName(),
        decimal: () => this.DECIMAL,
        numeric: () => this.NUMERIC,
        email: () => this.EMAIL,
        float: () => this.FLOAT,
        kana: () => this.CANA,
        pinCodeValidation: () => this.PINCODEVALIDATION,
        phoneNoValidation: () => this.PHONENOVALIDATION,
        numberValidation: () => this.NUMBERVALIDATION,
        emailValidation: () => this.EMAILVALIDATION,
        passwordValidation: () => this.PASSWORDLVALIDATION,
        minLength: () => this.minValue(),
        between: () => this.BETWEENRANGE,
        sameAsPassword: () => this.SAMEPASSWORD,
        sameAsEmail: () => this.SAMEEMAIL,
        maxLength: () => this.maxValue(),
        decim: () => this.DECIM,
        password: () => this.password(),
        notSameEmail: () => this.notSameEmail(),
        multipleEmailValidation: () => this.MULTILPEEMAILALIDATION,
        newPasswordValidation: () => this.PASSWORDLVALIDATION,
        senitize: () => this.SENITIZE,
        allowAddress: () => this.ALLOWADDRESS,
        senitizeNcModel: () => this.SENITIZENCMODEL,
        senitizeMtbName: () => this.SENITIZEMTBNAME,
        sanitizedUrl: () => this.SANITIZEDURL,
        sanitizedDescription: () => this.SANITIZEDDESCRIPTION
      },
      REQUIREDFIELD: "",
      canShowError: false,
      DECIMAL: "",
      NUMERIC: "",
      PLEASEPROVIDE: "",
      EMAIL: "",
      FLOAT: "",
      CANA: "",
      VALIDPASSWORD: "",
      BETWEENRANGE: "",
      SAMEPASSWORD: "",
      MAXVALUE: "",
      RANGENOTE: "",
      MINLENGTH: "",
      DECIM: "",
      PASSWORDVALIDATION1: "",
      PASSWORDVALIDATION2: "",
      MAXVALUEFIRSTSTRING: "",
      MAXVALUESECONDSTRING: "",
      NOTSAMEEMAIL: "",
      MINLENGTHFIRST: "",
      MINLENGTHSEOND: "",
    };
  },
  props: {
    control: {
      type: [Array, Object],
      default: () => [],
    },
    field: {
      type: String,
      default: "",
    },
  },

  methods: {
    /**
     * Method for showing required field error
     * Returns concatenated error message with field name
     */
    fieldName() {
      if (this.field) {
        if (this.$i18n.locale == "ja") {
          return this.PLEASEPROVIDE;
        }
        return this.PLEASEPROVIDE;
      }
      return this.REQUIREDFIELD;
    },

    /**
     * Method for showing Maximumn value error
     * Returns error messgae
     */
    maxValue() {
      if (this.control.$model.length > this.control.$params.maxLength.max) {
        return (
          this.MAXVALUEFIRSTSTRING +
          " " +
          this.control.$params.maxLength.max +
          " " +
          this.MAXVALUESECONDSTRING
        );
      }
    },
    /**
     * Method for showing Minimum value error
     * Returns error messgae
     */
    minValue() {
      if (this.control.$model.length < this.control.$params.minLength.min) {
        return (
          this.MINLENGTHFIRST +
          " " +
          this.control.$params.minLength.min +
          " " +
          this.MINLENGTHSEOND
        );
      }
    },
    password() {
      if (this.control.$model.length < this.control.$params.password.min)
        return (
          this.PASSWORDVALIDATION1 +
          this.control.$params.password.min +
          this.PASSWORDVALIDATION2
        );
    },
    /**
     * Method to get error message based on params passed
     */
    getMessage(type, params) {
      return this.errorMessages[type](params);
    },

    /**
     * Method which returns error message
     */
    showErrors() {
      return (
        this.control.$invalid && this.control.$anyError && this.control.$dirty
      );
    },
    /**
     * Method to get list of errors
     */
    listOfErrors() {
      const params = this.control.$params;
      return Object.keys(params).map((field) => {
        if (!this.control[field]) {
          return this.getMessage(field, params);
        }
      });
    },
    selectAlteastOneDropDownOption() {
      return this.PLEASEPROVIDE;
    },
    notSameEmail() {
      return this.NOTSAMEEMAIL;
    },
  },
  mounted() {
    this.REQUIREDFIELD = this.$t("REQUIREDFIELD");
    this.DECIMAL = this.$t("DECIMAL");
    this.NUMERIC = this.$t("NUMERIC");
    this.PLEASEPROVIDE = this.$t("app.generic.pleaseprovide");
    this.EMAIL = this.$t("app.validationerror.email");
    this.FLOAT = this.$t("DECIMAL");
    this.CANA = this.$t("KANAERORMSG");
    this.VALIDPASSWORD = this.$t("VALIDPASSWORD");
    this.BETWEENRANGE = this.$t("app.between.range");
    this.SAMEPASSWORD = this.$t("app.password.mustbesame");
    this.SAMEEMAIL = this.$t("app.email.mustbesame");
    this.MAXVALUE = this.$t("MAXLENGTH");
    this.MAXVALUEFIRSTSTRING = this.$t(
      "app.validationerror.maxlengthFirstString"
    );
    this.MAXVALUESECONDSTRING = this.$t(
      "app.validationerror.maxlengthScrondString"
    );
    this.RANGENOTE = this.$t("RANGENOTE");
    this.MINLENGTH = this.$t("MINLENGTH");
    this.DECIM = this.$t("DECIM");
    this.PASSWORDVALIDATION1 = this.$t("app.password.validation1");
    this.PASSWORDVALIDATION2 = this.$t("app.password.validation2");
    this.PINCODEVALIDATION = this.$t("app.validationerror.pincode");
    this.PHONENOVALIDATION = this.$t("app.validationerror.phoneNumber");
    this.NUMBERVALIDATION = this.$t("app.validationerror.numbervalidation");
    this.EMAILVALIDATION = this.$t("app.validationerror.email");
    this.PASSWORDLVALIDATION = this.$t("app.validationerror.password");
    this.NOTSAMEEMAIL = this.$t("app.validationerror.notSameEmail");
    this.MINLENGTHFIRST = this.$t("app.min.validation.string.one");
    this.MINLENGTHSEOND = this.$t("app.min.validation.string.two");
    this.MULTILPEEMAILALIDATION = this.$t("app.validationerror.email");
    this.SENITIZE = this.$t("app.validationerror.senitize");
    this.ALLOWADDRESS = this.$t("app.validationerror.allowAddress");
    this.SENITIZENCMODEL = this.$t("app.validationerror.senitizeNcModel");
    this.SENITIZEMTBNAME = this.$t("app.validationerror.senitizeMtbName");
    this.SANITIZEDURL =  this.$t("app.validationerror.sanitizedUrl");
    this.SANITIZEDDESCRIPTION =  this.$t("app.validationerror.sanitizedDescription");
  },
};

<template>
      <div class="">
      <div class="d-flex mb-2">
          <span class="page-header-title">Shipment Data</span>
    </div>
  <CCard>
    <CCardBody>
      <div class="d-md-flex d-sm-flex justify-content-end mt-3">
        <div>
          <b-input-group class="">
            <template #append>
              <b-input-group-text @click="OnSearch(searchText)">
                <CIcon name="cil-search" class="cicon cursor" />
              </b-input-group-text>
            </template>
            <b-form-input
              v-model="searchText"
              placeholder="NC Serial No"
              v-on:keyup.enter="OnSearch(searchText)"
            >
            </b-form-input>
          </b-input-group>
        </div>

        <label
          class="btn btn-default btn-primary float-right ml-3 mt-3 mt-lg-0 mt-md-0 mt-sm-0"
        >
          <em class="fa fa-upload" aria-hidden="true"></em> Upload
          <input
            id="fileupload"
            ref="imageUploader"
            type="file"
            @click="resetImageUploader"
            hidden
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            v-on:change="uploadFile($event)"
          />
        </label>
      </div>

      <div class="table-responsive mt-2">
        <CustomTableUser
          class="vertical-align-middle table-responsive account_table"
          :items="shipmentDataList"
          hover
          striped
          border
          :small="'small'"
          fixed
          caption
          :fields="fields"
          :per-page="perPage"
          :total="total"
          :loadTableData="loadTableData"
          :rowSelectHandler="rowClickHandler"
          :onRowEditHandler="editTableData"
          :onRowSpamHandler="editSpam"
          :rowFliterHandler="rowFliterHandler"
          :onRowUnSpamHandler="editUnSpam"
          :onRowApprovalHandler="editApprove"
          :currentPagination="
            shipmentDataListFilter.filters.start == 0
              ? 1
              : currentPageOfShipmentTable
          "
        />
      </div>
    </CCardBody>

    <b-modal
      hide-header
      id="editShipmentDetails"
      title="Are you sure want to change the role ?"
      ok-title="Yes"
      cancel-title="Cancel"
    >
      <form>
        <div class="form-group">
          <div class="form-group">
          <div class="d-flex mb-2">
          <span class="page-header-title">Shipment Data Edit</span>
          </div>
            <div class="form-group">
              <label for="exampleInputEmail1" class="form-label ml-3 mb-0 mb-0"
                >NC Model</label
              >

              <CInput
                v-model.trim="$v.shipmentEditDetails.model_no.$model"
                placeholder="NC Model"
                type="text"
                class="col-lg-12 cols-6 m-0"
                required
                was-validated
              />
              <show-errors
                class="ml-3 mt-1"
                :control="$v.shipmentEditDetails.model_no"
                :field="
                  $t('app.master.tab.basicinfo.kinderinputIP.placeholder')
                "
              />
            </div>

            <label for="exampleInputEmail1" class="form-label ml-3 mb-0"
              >NC Serial Number</label
            >

            <CInput
              v-model.trim="$v.shipmentEditDetails.nc_serial_no.$model"
              placeholder="NC Serial Number"
              type="text"
              class="col-lg-12 cols-6 m-0"
              required
              was-validated
            />
            <show-errors
              class="ml-3 mt-1"
              :control="$v.shipmentEditDetails.nc_serial_no"
              :field="$t('app.master.tab.basicinfo.kinderinputIP.placeholder')"
            />
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1" class="form-label col-12 mb-0 mb-0"
              >Date of Issue</label
            >
            <date-picker
              v-model="$v.shipmentEditDetails.shipment_date.$model"
              valueType="format"
              format="YYYY-MM-DD"
              :input-class="[
                this.$v.shipmentEditDetails.shipment_date.$error == true
                  ? 'col-12 m-0 form-control is-invalid ml-3  mx-input'
                  : 'col-12 ml-3 form-control form-group was-validated mx-input',
              ]"
              placeholder="YYYY-MM-DD"
            ></date-picker>
            <show-errors
              class="ml-3 mt-1"
              :control="$v.shipmentEditDetails.shipment_date"
              :field="$t('app.master.tab.basicinfo.kinderinputIP.placeholder')"
            />
          </div>
          <div>
            <label for="exampleInputEmail1" class="form-label ml-3 mb-0"
              >MTB</label
            >

            <CInput
              v-model.trim="$v.shipmentEditDetails.controller_type.$model"
              placeholder="MTB"
              type="text"
              class="col-lg-12 cols-6 m-0"
              required
              was-validated
            />
            <show-errors
              class="ml-3 mt-1"
              :control="$v.shipmentEditDetails.controller_type"
              :field="$t('app.master.tab.basicinfo.kinderinputIP.placeholder')"
            />
          </div>
        </div>
      </form>
      <template v-slot:modal-footer="{}">
        <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
          <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
            <b-button
              size="md"
              variant="secondary"
              class="ml-2 px-2"
              @click="$bvModal.hide('editShipmentDetails')"
            >
              Cancel
            </b-button>
            <b-button
              size="md"
              variant="primary"
              class="ml-2 px-2"
              @click="updateShipmentDetails"
              >Save
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="shipmentDataErrorModal"
      size="xl"
      title="Below shipment data not uploaded."
      ok-title="Save"
      cancel-title="Cancel"
    >
      <div>
        <div class="mt-3">
          <div
            v-if="this.shipmentNotUploadedData.length > 0"
            class="table-responsive entry-custom-calender-responsive"
          >
            <table
              ref="calendarTable" class="entry-custom-calender table table-bordered table-striped" aria-describedby="calTable"
            >
              <tr>
                <th scope="col" class="sticky th-top-class text-bgm">
                  NC Model
                </th>
                <th scope="col" class="sticky th-top-class text-bgm">
                  NC Serial Number
                </th>
                <th scope="col" class="sticky th-top-class text-bgm">
                  Date of Issue
                </th>

                <th scope="col" class="sticky th-top-class text-bgm">MTB</th>
                <th scope="col" class="sticky th-top-class text-bgm">Reason</th>
              </tr>

              <tbody class="value-bgm">
                <tr v-for="values in shipmentNotUploadedData" :key="values.id">
                  <td>
                    <div class="d-flex margin-top-value">
                      {{ values.nc_model ? values.nc_model : "" }}
                    </div>
                  </td>
                  <td>
                    <div class="d-flex margin-top-value">
                      {{
                        values.nc_serial_number ? values.nc_serial_number : ""
                      }}
                    </div>
                  </td>
                  <td>
                    <div class="d-flex margin-top-value">
                      {{ values.date_of_issue ? values.date_of_issue : "" }}
                    </div>
                  </td>
                  <td>
                    <div class="d-flex margin-top-value">
                      {{ values.mtb ? values.mtb : "" }}
                    </div>
                  </td>
                  <td>
                    <div class="d-flex margin-top-value">
                      {{ values.message ? values.message : "" }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <template v-slot:modal-footer="{}">
        <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
          <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
            <b-button
              size="md"
              variant="secondary"
              class="ml-2 px-2"
              @click="$bvModal.hide('shipmentDataErrorModal')"
              >Close
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </CCard>
  </div>
</template>

<script>
import { multipleErrorMessages,senitize,senitizeNcModel,senitizeMtbName } from "@/shared/utils";
import _ from "lodash";
import {
  SHIPMENT_DATA_LIST,
  UPDATE_SHIPMENT_DETAILS,
  UPLOAD_SHIPMENT_FILE,
} from "@/store/actions.type";
import CustomTableUser from "@/components/Table/TableUser.vue";
import ShowErrors from "@/components/ShowErrors/ShowErrors.vue";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  name: "shipmentDataList",
  components: { CustomTableUser, ShowErrors, DatePicker },
  data() {
    return {
      shipmentDataList: [],
      searchText: "",
      shipmentDataListFilter: {
        filters: {
          start: 0,
         limit: 50,
          sort_by: "created_at",
          order_by: "desc",
          role_id: [],
          status: [],
        },
        search: "",
      },
      currentPage: 1,
      pageStart: 0,
      perPage: 50,
      total: 0,
      sortField: "created_at",
      sortDirection: "desc",
      currentPageOfShipmentTable: 1,
      shipmentEditDetails: {
        controller_type: "",
        id: "",
        model_no: "",
        nc_serial_no: "",
        shipment_date: "",
      },
      shipmentNotUploadedData: [],

      fields: [
        {
          key: "model_no",
          sorter: true,
          label: "NC Model",
          _classes: "tbl-width-50",
        },
        {
          key: "nc_serial_no",
          label: "NC Serial No",
          sortable: true,
          _classes: "tbl-width-50",
        },
        {
          key: "shipment_date",
          label: "Date of Issue",
          sortable: true,
          _classes: "tbl-width-60",
        },

        {
          key: "controller_type",
          label: "MTB",
          sorter: true,
          _classes: "tbl-width-50",
        },

        {
          key: "shipmentAction",
          label: "Action",
          sortable: false,
          _classes: "tbl-width-40 text-center",
        },
      ],
      shipmentNotUploadedFileds: [
        {
          key: "nc_model",
          sorter: false,
          label: "NC Model",
          _classes: "tbl-width-50",
        },
        {
          key: "nc_serial_number",
          label: "NC Serial Number",
          sorter: false,
          _classes: "tbl-width-50",
        },
        {
          key: "date_of_issue",
          label: "Date of Issue",
          sorter: false,
          _classes: "tbl-width-40",
        },
        {
          key: "mtb",
          label: "MTB",
          sorter: false,
          _classes: "tbl-width-50",
        },

        {
          key: "message",
          label: "Reason",
          sorter: false,
          _classes: "tbl-width-60 text-center",
        },
      ],
    };
  },
  //validation for the fields
  validations: {
    shipmentEditDetails: {
      controller_type: { required,senitizeMtbName },
      id: { required },
      model_no: { required,senitizeNcModel },
      nc_serial_no: { required,senitize },
      shipment_date: { required },
    },
  },
  methods: {
    changeColor() {
      document.documentElement.style.setProperty("--sidebarBgColor", "red");
    },

    /**
     * @method redirectToAddScreen
     * @description Method to redirect to add screen
     * @author @Darshan_Katthige_R
     */
    redirectToAddScreen() {
      this.$router.push({
        name: "manageradd",
      });
    },

    /**
     * @method rowFliterHandler
     * @description Method to load table data with filter
     * @author @Darshan_Katthige_R
     */
    async rowFliterHandler(e) {
      this.sortDirection = e.asc ? "asc" : "desc";
      this.sortField = e.column;
      this.fetchShipmentData();
    },

    /**
     * @method editTableData
     * @description Opens edit popup
     * @author @Darshan_Katthige_R
     */
    editTableData(item) {
      this.shipmentEditDetails = {
        controller_type: item.controller_type,
        id: item.id,
        model_no: item.model_no,
        shipment_date: item.shipment_date,
        nc_serial_no: item.nc_serial_no,
      };
      this.$bvModal.show("editShipmentDetails");
    },

    /**
     * @method OnSearch
     * @description To handle on search of table data
     * @author @Darshan_Katthige_R
     */
    async OnSearch(searchText) {
      this.searchText = searchText;
      this.currentPage = 1;
      this.fetchShipmentData();
    },

    /*
    /**
     * @method loadTableData
     * @description Method to load table data
     * @author @Darshan_Katthige_R
     */
    async loadTableData(currentPage, limit) {
      this.currentPage = currentPage;
      this.pageStart =
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.perPage;
      this.perPage = limit;
      this.currentPageOfShipmentTable = currentPage;
      this.fetchShipmentData();
    },

    /**
     * @method fetchShipmentData
     * @description Tt will fetch all users data
     * @author @Darshan_Katthige_R
     */
    async fetchShipmentData() {
      this.shipmentDataListFilter.filters.start =
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.perPage;
      this.shipmentDataListFilter.filters.limit = this.perPage;
      this.shipmentDataListFilter.filters.sort_by = this.sortField;
      this.shipmentDataListFilter.filters.order_by = this.sortDirection;
      this.shipmentDataListFilter.search = this.searchText;
      this.isLoadingFetchShipment = this.$loading.show();
      let data = await this.$store.dispatch(
        SHIPMENT_DATA_LIST,
        this.shipmentDataListFilter
      );
      this.isLoadingFetchShipment.hide();
      if (_.get(data, "success", true)) {
        this.shipmentDataList = _.get(data, "data.model.list", []);
        this.total = _.get(data, "data.model.total", 0);
      } else {
        let message = _.get(data, "message", "");
        this.shipmentDataList = [];
        this.total = 0;
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageobj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageobj);
        }
      }
    },

    /**
     * @method updateShipmentDetails
     * @description Tt will update all shipment data
     * @author @Darshan_Katthige_R
     */
    async updateShipmentDetails() {
      this.$v.$touch();
      if (!this.$v.shipmentEditDetails.$invalid) {
        this.$bvModal.hide("editShipmentDetails");

        this.isLoadingUpdateShip = this.$loading.show();
          await this.$store.dispatch(UPDATE_SHIPMENT_DETAILS, this.shipmentEditDetails)
          .then((data) => {
            if (_.get(data, "data.success", false)) {
              this.isLoadingUpdateShip.hide();
              this.$toast.removeAll();
              this.$toast.success({
                message: _.get(data, "data.message", ""),
              });
              this.fetchShipmentData();
            } else {
              this.isLoadingUpdateShip.hide();
              let message = _.get(data, "message", "");
              if (message.length > 0) {
                this.$toast.error({
                  message: message,
                });
              } else {
                this.$toast.removeAll();
                let messageObj = _.get(data, "message", "");
                multipleErrorMessages(this.$toast, messageObj);
              }
              this.fetchShipmentData();
            }
          });


      }
    },

    /**
     * @method uploadFile
     * @description Tt will upload file data
     * @author @Darshan_Katthige_R
     */
    async uploadFile(event) {
      this.shipmentNotUploadedData = [];
      let file = event.target.files[0];

        if (file.size / 1000 > 5120) {
          this.$toast.removeAll();
          this.$toast.error("Size exceeded");
          return;
      }

       if (
        ![
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel"
        ].includes(file.type)
      ) {
        this.$toast.removeAll();
        this.$toast.error("File type invalid");
        return;
      }


      let formData = new FormData();
      formData.append("file", file);

      this.isLoadingFileUpload = this.$loading.show();
      let data = await this.$store.dispatch(UPLOAD_SHIPMENT_FILE, formData);
      this.isLoadingFileUpload.hide();
      if (_.get(data, "success", true)) {
        this.$toast.removeAll();
        this.shipmentNotUploadedData = _.get(data, "data.item", "");
        if (this.shipmentNotUploadedData.length > 0) {
          this.$toast.error(_.get(data, "data.message", ""));
          this.$bvModal.show("shipmentDataErrorModal");
        } else {
          this.$toast.success(_.get(data, "data.message", ""));
        }
        this.fetchShipmentData();
      } else {
        this.$toast.removeAll();
        this.shipmentNotUploadedData = [];
          this.$toast.error(_.get(data, "message", ""));
      }
    },

    /**
     * @method resetImageUploader
     * @description Tt will reset file data
     * @author @Darshan_Katthige_R
     */
    resetImageUploader() {
      this.$refs.imageUploader.value = "";
    },
  },
  async created() {
    this.fetchShipmentData();
  },
};
</script>
<style>
@media (max-width: 426px) {
  .first-check {
    width: 116px !important;
  }
}

@media (max-width: 321px) {
  .d-flex {
    display: block !important;
  }

  .first-check {
    width: 66px !important;
  }
}

.manager_action div {
  width: 50px !important;
}

.phone-no div {
  min-width: 120px !important;
}

.userName div {
  width: 150px !important;
}

.company_name {
  min-width: 200px !important;
}

.roleselectbox {
  min-width: 238px !important;
}

.invalid-datepicker {
  border-color: #e55353;
}


.entry-custom-calender {
  font-size: 0.75rem;
  border-collapse: separate !important;
  border-spacing: 0 !important;
}
.entry-custom-calender-responsive::-webkit-scrollbar {
  width: 5px !important;
}

.entry-custom-calender-responsive::-webkit-scrollbar-thumb {
  background-color: #989898 !important;
}

.entry-custom-calender-responsive::-webkit-scrollbar-thumb {
  background-color: #989898 !important;
}
.entry-custom-calender-responsive {
  max-height: 400px !important;
}
.entry-custom-calender-responsive::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.entry-custom-calender tr td {
  border: 1px solid #e7e7e7;
  z-index: 1;
}


.entry-custom-calender tr th {
  border: 1px solid rgba(237, 241, 242, 0.5);
}

.entry-custom-calender tr .sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 2;
   background: #d11016 !important;
}

.th-top-class {
  top: 0.5px !important;
}

.disabled-em-element {
  pointer-events: none !important;
  color: #989898 !important;
}

.text-bgm {
  color: #fff !important;
  font-size: 14px !important;
}

.value-bgm td {
  font-size: 14px !important;
}
</style>
